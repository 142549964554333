.promote-container {
    background-image: url('../../images/promote/bg-large-2.webp');
    padding: 22px;
}

.promote1-title {
    font-family: HelveticaNeue;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.promote1-subtitle {
    font-family: HelveticaNeue;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.promote-button-container {
    margin: auto;
}

.promote1-button {
    border: none;
    width: 150px;
    height: 45px;
    background-color: #f97d5e;
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 8px;
}

@media (max-width: 575px) {
    .promote1-title {
        font-size: 4vw;
    }
}