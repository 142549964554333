body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-card {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.bg-content {
  background: #e9e9f2;
}

@media only screen and (max-width: 575px) {
  .bg-content {
    background: #ffffff;
  }
}

.bg-white {
  background: #ffffff;
}

@media only screen and (max-width: 991px) {
  .fb_dialog_content iframe {
    margin-bottom: 32px !important;
  }
}