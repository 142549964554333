@font-face {
    font-family: 'Open Sans';
    src: url(./fonts/Open_Sans/OpenSans-Regular.ttf)
}

* {
    font-family: Open Sans;
}

html {
    max-width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
}

body {
    height: calc(100vh - 0px);
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none !important;
}

.can-click {
    cursor: pointer;
}

.wraper {
    position: fixed;
    background: #0000007d;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.na-no-hover-text-nomal {
    color: #000;
    font-family: HelveticaNeue;
    font-size: 20px;
}

.na-text-cancel {
    height: 30px;
    color: #808080;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
}

.na-text-accept {
    height: 30px;
    color: #ffcc00;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
}

[class*="na-text-"]:hover {
    background-color: #ffcc00;
    color: #fff;
    border-radius: 8px;
}

.mrel {
    font-family: KaTeX_Main, "Times New Roman", serif;
}

.new-lesson {
    position: absolute;
    bottom: 7px;
    padding: 2px 7px;
    border-radius: 3px;
    color: white;
    background-color: #e50914;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(0.6rem, 1.5vw, 0.85rem);
    font-weight: bold;
    white-space: nowrap;
}

.new-lesson-list {
    position: absolute;
    bottom: 3px;
    padding: 2px 7px;
    border-radius: 3px;
    color: white;
    background-color: #e50914;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7rem;
    font-weight: bold;
    white-space: nowrap;
}

.new-update {
    position: absolute;
    bottom: 10px;
    padding: 2px 7px;
    border-radius: 3px;
    color: white;
    background-color: #e50914;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(0.6rem, 1.5vw, 0.85rem);
    font-weight: bold;
    white-space: nowrap;
}

.live-stream-schedule {
    white-space: nowrap;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 15px;
    scroll-behavior: smooth;
    display: flex;
}

.live-stream-schedule::-webkit-scrollbar {
    display: none;
}

.livestream-schedule-table-title {
    font-size: clamp(18px, 2vw, 30px);
    font-weight: bold;
    padding-left: 15px;
    max-width: 65vw;
}

.video-image-premium {
    border: 4px solid #FFCC00;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(255, 204, 0, 0.6);
}

.rainbow-text {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rainbow {
    text-align: center;
    text-decoration: underline;
    font-size: 32px;
    font-family: monospace;
    letter-spacing: 5px;
}

/* .rainbow_text_animated {
    background: linear-gradient(to left, #8678dd, #62afdf, #83d45a, #f9e35f, #f95151);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
} */

@keyframes rainbow_animation {
    0%,
    100% {
        background-position: 0 0;
    }
    50% {
        background-position: 100% 0;
    }
}

.announcement-list-container {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    white-space: nowrap;
    scroll-behavior: smooth;
    flex-wrap: nowrap !important;
}

.announcement-list-container::-webkit-scrollbar {
    display: none;
}

.ls-playback-list-container {
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 15px;
    scroll-behavior: smooth;
    display: flex;
    padding-bottom: 15px;
}

.ls-playback-list-container::-webkit-scrollbar {
    display: none;
}

.live-stream-item {
    height: auto;
    display: inline-block;
}

.video-tag-info-ls {
    position: static;
    left: 10px;
    top: 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 5px 5px 0px 0px;
    overflow-wrap: break-word;
    display: inline-block;
    background: #FFFFFF;
    border-radius: 20px;
    cursor: pointer;
}

