.choice-exam-value {
    max-width: 280px;
}

.select-count-wrong {
    border-color: #FE3B30;
    background: #FE3B30;
    color: white;
}

.select-count-right {
    border-color: #19B71F;
    background: #19B71F;
    color: white;
}

.count-dot {
    background-color: purple;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto
}

.exam-section-none {
    margin: 10px;
    min-width: 400px;
    /* background: #F7F7F8; */
    /* border: 2px solid #EFEFF4; */
    /* border-radius: 20px; */
    padding: 15px;
    cursor: default;
}

.explain-video-container {
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
    min-height: 370px;
    margin: auto;
}