.live-stream-table-list-title {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
}

.live-stream-item p {
    white-space: break-spaces;
}

.live-stream-item:last-child {
    margin-right: 15px;
}

.live-stream-item-logo {
    width: 100%;
    border-radius: 0.7vw;
    border: solid 2px #e9e9f2;
    /* width: calc(100% - 1px);
    height: calc(100% - 1px); */
    /* max-width: 225px;
    max-height: 115px; */
    /* width: 198px;
    height: 105px; */
}

.live-stream-item-image {
    padding: 4px;
    position: relative;
}

.live-stream-item-image.select {
    padding: 3px;
}

.live-stream-item-logo.select {
    border: solid 4px #fe0000;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
}

.live-stream-item-selected {
    width: 100%;
    height: 100%;
    top: 0%;
    position: absolute;
    border-radius: 10px;
    padding: 6px;
}

.live-stream-item-unselected {
    width: 100%;
    height: 100%;
    top: -100%;
    position: relative;
    padding: 10px;
}

.live-stream-item-playing-text {
    /* width: 50px; */
    height: 20px;
    border-radius: 23px;
    border: solid 1px #d1d1d6;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    padding: 2px 5px 2px 5px;
    justify-content: center;
    align-items: center;
}

.live-stream-item-broadcasting-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.live-stream-item-broadcasting-text {
    /* width: 20px; */
    height: 20px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    padding: 2px 5px 2px 5px;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.is-live {
    margin-left: 5px;
}

.livestream-schedule-table-sub-title {
    font-size: clamp(13px, 1.2vw, 20px);
    padding-left: 15px;
}

.live-stream-item-date-title {
    margin: 0;
    font-size: clamp(13px, 1.2vw, 16px);
}

.live-stream-item-title {
    font-size: clamp(13px, 1.2vw, 20px);
    word-break: break-word;
    white-space: break-spaces;
}

.livestream-schedule-scroll-left-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
    margin-right: 4px;
    transform: rotate(180deg);
}

.livestream-schedule-scroll-right-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
}

.livestream-schedule-scroll-button-container {
    width: clamp(60px, 7vw, 100px);
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
}

.livestream-notification-text {
    font-size: 0.9em;
    float: left;
}