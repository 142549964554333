.livestream-page-container {
    width: 100%;
    height: 100%;
    margin: 0;
}

.menu-room {
    justify-content: center;
    margin: 40px 0px 15px 0px;
    /* margin: 15px 0px 15px 0px; */
    display: flex;
    padding: 0px 10px 0px 10px;
}

.exam-room {
    background: #0D7BFF;
    background-image: url("../../images/background/certificate-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 5px;
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
    display: flex;
    cursor: default;
}

.exam-room-text {
    color: white;
    margin: auto 10px auto 15px;
}

.exam-room-title {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
}

.score-room {
    margin-left: 5px;
    background: black;
    background-image: url("../../images/background/score-bg.svg"), url("../../images/background/score-bg-under.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
    display: flex;
    cursor: pointer;
}

.score-text {
    color: white;
    margin: auto;
}

.subject-room {
    margin: 25px 0px 15px 55px;
    padding: 0px 10px 0px 10px;
}

.subject-room-subject {
    color: black;
    background: white;
    border-radius: 10px;
    padding: 5px 10px 5px 20px;
    margin-right: 15px;
    border: 2px solid #EFEFF4;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subject-icon {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 42px;
    height: 42px;
}

.select-exam {
    color: white;
    background: #0B7AFF;
}

.start-exam-img {
    max-width: 25px;
}

.subject-radio {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
}

input[type='radio'] {
    accent-color: #4CD964;
    background-color: #BBDBFF;
    border: red;
}

.start-exam {
    color: white;
    background: #0B7AFF;
    border: none;
    margin: 50px auto auto auto;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
}