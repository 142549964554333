.skip-question-container {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  margin-top: 15px;
  background-color: white;
}

.skip-question-button {
  width: clamp(85px, 7vw, 105px);
  height: clamp(36px, 3vw, 44px);
  border-radius: 12px;
  border: solid 1px #efeff4;
  background-color: #ffffff;
  margin: 0px 15px 15px 0px; 
  font-size: clamp(10px, 0.8vw, 13px);
  font-weight: bold;
  color: #000000;
}

.skip-question-button.active {
  background-color: #0b7aff;
  border: solid 0px white;
  color: #ffffff;
  outline: none;
}