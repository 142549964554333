.button-open-app {
    /* width: 24px; */
    height: 19px;
    font-size: 15px;
    font-weight: 500;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0b7aff;
}

.layout-open-app {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    justify-content: space-between;
    box-shadow: #ccc 0px 0px 1px 1px;
}

.layout-open-app.regular {
    padding: 0.2rem 1rem;
}

.container-open-app {
    position: fixed;
    width: 100vw;
    z-index: 99;
    bottom: 1px;
}

.text1-open-app {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.text2-open-app {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

#mobile-register-button {
    width: 100%;
    height: 50px;
    background-color: #f97d5e;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.message-us-button-tab {
    display: flex;
}

.msg-icon-tab {
    width: 20px;
    height: 20px;
}