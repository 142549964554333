.login-bg {
    width: 90%;
    height: fit-content;
    max-width: 465px;
    max-height: 700px;
    border-radius: 20px;
    background-color: #ffffff;
    animation: pop 0.3s;
    z-index: 5;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: #ccc !important;
    border-radius: 8px;
}

.login-layout {
    width: 100%;
    height: 100%;
    z-index: 4;
}

#phone {
    border-width: 0px 0px 1px 0px;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e93;
    width: 310px;
}

#login-button2 {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background-color: #f97d5e;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.login-facebook-button2 {
    width: 100%;
    height: 50px;
    border-radius: 12px;
    background-color: #1678f2;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-facebook {
    margin-right: 10px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    width: 330px;
    height: 0;
    border: solid 1px #efeff4;
}

.login-text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #808080;
}

.login-description {
    text-align: initial;
    line-height: 1.5;
}

@keyframes pop {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.otp-container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.otp-input {
    width: 60px !important;
    height: 60px;
    border-radius: 15px;
    background-color: #efeff4;
    border: none;
    padding: 2px;
    text-align: center;
    font-size: 2em;
    margin: 0px 3px;
}

.bg-sign-up {
    z-index: 5;
    border-radius: 20px;
    background-color: #ffffff;
    animation: pop 0.3s;
    max-width: 465px;
    height: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    overflow: scroll;
    overflow-x: hidden;
}

.input-signup img {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 0;
    bottom: 0;
    left: 25px;
    margin: auto;
}

.input-signup input {
    width: 100%;
    height: 50px;
    padding-left: 34px;
    border-radius: 12px;
    border: solid 1px #efeff4;
    background-color: #ffffff;
}

.input-signup select {
    width: 100%;
    height: 50px;
    padding-left: 34px;
    border-radius: 12px;
    border: solid 1px #efeff4;
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: black;
}

.input-signup .drop {
    left: unset !important;
    right: 5% !important;
    width: 13px !important;
    height: 13px !important;
    margin: auto 15px;
}

.select {
    border-color: #f97d5e;
}

.mobile-number-input {
    margin-top: 30px;
}

.mobile-number-detail {
    margin-top: 30px;
    margin-bottom: 20px;
}

.register-line {
    margin-top: 30px;
}

.register-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invalid-email {
    color: red;
    font-weight: bold;
}

.login-offer-bg {
    max-width: 70%;
    max-height: 90%;
}

.login-offer-container {
    justify-content: center;
    gap: 15px;
}

.login-offer-close {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 2;
}

.login-offer-title {
    font-size: clamp(30px, 3vw, 40px);
}

.login-offer-description {
    font-size: clamp(16px, 1.4vw, 28px);
    padding: 0px 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
}

.login-offer-description ul {
    padding-left: 20px;
}

.login-offer-list-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    scroll-behavior: smooth;
    padding-bottom: 20px;
    margin: auto 25px;
}

.login-offer-list-container::-webkit-scrollbar {
    display: none;
}

.login-offer-item {
    flex: 0 0 auto;
}



.login-offer-course-item {
    border-radius: 1vw;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    border: #F4F5F9 2px solid;
    width: 18vw;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    position: relative;
}

.login-offer-course-item:hover {
    border: #ffcc00 2px solid
}

.login-offer-course-item .badge-img {
    width: 5.5vW;
    top: 0px;
    right: 0px;
    position: absolute;
}

.login-offer-course-item .product-description {
    font-size: large;
    margin-top: 15px;
}

.login-offer-course-item .bargain-description {
    min-height: 24px;
    color: #ffb700;
}

.login-offer-course-item .bargain-container {
    margin-top: 8px;
}

.login-offer-course-item .bargain-container .bargain-old-price {
    text-decoration-line: line-through;
    color: #5B5C62;
    font-size: larger;
    font-weight: bold;
}

.login-offer-course-item .bargain-container-blank {
    min-height: 38px;
}

.login-offer-course-item .price-main {
    font-size: 40px;
    margin-top: -18px;
}

.login-offer-course-item .payment-description {
    margin: 10px;
    color: grey;
    font-size: smaller;
}

.login-offer-course-item .subscribe-button {
    border: #e9e9f2 solid 2px;
    border-radius: 10px;
    margin: 10px 45px;
    padding: 7px;
}

.login-offer-course-item-images {
    display: flex;
    margin-top: 10px;
    justify-content: center;
}

.login-offer-course-item-images .login-offer-course-item-image-container {
    color: gray;
    font-size: smaller;
    margin-right: 5px;
    margin-left: 5px;
}

.login-offer-course-item-images .login-offer-course-item-image-container .login-offer-course-item-image {
    height: 2.5vh;
}




@media screen and (max-width: 991px) {
    .login-offer-bg {
        width: 95%;
        max-width: 95%;
    }

    .login-offer-close {
        right: 4%;
    }

    .login-offer-course-list-scroll-left-button,
    .login-offer-course-list-scroll-right-button {
        display: none;
    }

    .login-offer-course-item {
        width: 100%;
    }

    .login-offer-course-item-image {
        width: 100%;
        margin: 10px auto;
    }
}

@media screen and (max-width: 575px) {
    .register-button-container {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 460px) {
    .mobile-number-input {
        margin-top: 5px;
    }

    .mobile-number-detail {
        margin-top: 15px;
    }

    .register-line {
        margin-top: 20px;
    }

    .login-text {
        font-size: 13px;
    }
}