.livestream-page-container {
    width: 100%;
    height: 100%;
    margin: 0 !important;
}

.video-section-container {
    width: 100%;
    height: 100%;
    background-color: #f7f7fc;
}

.ls-top-right-buttons {
    position: absolute;
    top: 24px;
    right: 15px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: row-reverse;
}

.ls-quiz-button,
.ls-perfect-button,
.ls-ranking-button {
    color: white;
    padding: 8px 12px;
    font-size: clamp(12px, 0.9vw, 15px);
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
}

.ls-quiz-button {
    background: #0b7aff;
}

.ls-perfect-button {
    background: #00c45f;
}

.ls-ranking-button {
    background: #FF710B;
    display: flex;
    align-items: center;
    gap: 4px;
}

.live-title-container {
    padding: 15px;
}

.live-title-text {
    font-size: clamp(20px, 1.7vw, 30px);
    font-weight: bold;
}

.document-title-header-container {
    margin-top: clamp(20px, 1.7vw, 30px);
}

.document-title-header {
    font-size: clamp(20px, 1.7vw, 30px);
    font-weight: bold;
    line-height: 0.73;
}

.document-download-container {
    margin-top: clamp(14px, 1.3vw, 20px);
}

.document-download-button {
    width: 100%;
    height: clamp(60px, 6.5vw, 100px);
    border-radius: 20px;
    border: solid 1px #efeff4;
    background-color: #fcfcfd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(14px, 1.3vw, 20px);
    cursor: pointer;
}

.document-thumbnail-icon {
    width: clamp(40px, 4vw, 60px);
    height: clamp(40px, 4vw, 60px);
}

.document-download-text {
    font-size: clamp(14px, 1.3vw, 20px);
    font-weight: bold;
}

.document-download-icon {
    width: clamp(30px, 3vw, 40px);
    height: clamp(30px, 3vw, 40px);
}

.share-icon {
    margin: 6px 10px 0px 0px;
}

.livestream-page-number-tags {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    top: 24px;
    left: 15px;
}

.LS-comment,
.LS-viewers {
    color: white;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: pointer;
    padding: 2px 5px;
}

.LS-comment img,
.LS-viewers img {
    margin-right: 5px;
    padding-bottom: 2px;
}

.announce-board-button {
    width: 100%;
    height: clamp(60px, 6.5vw, 100px);
    border-radius: 20px;
    border: solid 1px #efeff4;
    background-color: #fcfcfd;
    justify-content: space-between;
    align-items: center;
    padding: clamp(14px, 1.3vw, 20px);
    cursor: pointer;
}



.perfect-score-board {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.55);
    text-align: center;
    width: 35vw;
    left: 25vw;
    min-height: 28vw;
    max-height: 80vh;
    overflow-y: auto;
    margin-bottom: 3%;
    z-index: 2;
}

.perfect-score-title {
    margin: 17px auto;
    font-size: xx-large;
    color: #ffcc00;
    font-weight: bold;
    text-shadow: 1px 1px #000000;
}

.perfect-score-rank {
    display: inherit;
    margin: 0px 35px 20px 35px;
    max-width: 28%;
    min-width: 24%;
}

.perfect-score-rank-img {
    border-radius: 50%;
    border: 6px solid red;
    max-width: 12vh;
    width: 12vh;
    max-height: 12vh;
    height: 12vh;
}

.perfect-score-rank-name {
    color: #ffffff;
    text-shadow: 1px 1px #000000;
}

.perfect-student-score-board {
    display: inline-table;
}

.perfect-score-close {
    color: white;
    text-align: right;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 10px;
}

.perfect-score-close img {
    max-width: 20px;
    float: right;
    cursor: pointer;
}



.ranking-score-board {
    position: absolute;
    background-color: white;
    text-align: center;
    width: 80%;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    min-height: 28vw;
    max-height: 80vh;
    overflow-y: auto;
    margin-bottom: 3%;
    user-select: none;
    z-index: 2;
    border-radius: 20px;
}

.ranking-score-close {
    color: white;
    text-align: right;
    cursor: pointer;
    padding-top: 20px;
    padding-right: 20px;
}

.ranking-score-close img {
    float: right;
    cursor: pointer;
}

.ranking-score-menu-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 15px;
}

.ranking-score-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
}

.ranking-score-live-name {
    font-weight: bold;
    color: grey;
    font-size: 15px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.ranking-score-top-background {
    background-color: #0b7aff;
    background-image: url("../../images/background/mask-group.png");
    background-size: cover;
}

.ranking-score-top-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 10px;
    justify-items: center;
    max-width: 60%;
    width: 100%;
    padding: 20px 0;
    margin: 0 auto;
}

.ranking-score-top-container>*:nth-child(1) {
    grid-column: 1 / span 2;
    text-align: center;
}

.ranking-score-top-container>*:nth-child(2),
.ranking-score-top-container>*:nth-child(3) {
    grid-column: auto;
    text-align: center;
}

.ranking-score-student-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    padding: 0 20px;
    margin: 20px auto;
}

.ranking-score-position {
    width: 30px;
    height: 30px;
    line-height: 29px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-align: center;
    letter-spacing: 1px;
}



#ranking-score-top-1 .ranking-score-position,
#ranking-score-top-2 .ranking-score-position,
#ranking-score-top-3 .ranking-score-position {
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
    line-height: 39px;
    border-radius: initial;
}

#ranking-score-top-1 .ranking-score-position {
    background-image: url('../../images/user/user-star-gold.svg');
}

#ranking-score-top-2 .ranking-score-position {
    background-image: url('../../images/user/user-star-red.svg');
}

#ranking-score-top-3 .ranking-score-position {
    background-image: url('../../images/user/user-star-blue.svg');
}

#ranking-score-top-1 .ranking-score-rank-img {
    border-color: #FFA000;
}

#ranking-score-top-2 .ranking-score-rank-img {
    border-color: #FE2D55;
}

#ranking-score-top-3 .ranking-score-rank-img {
    border-color: #5856CF;
}



.ranking-score-profile {
    margin: auto -18px;
    position: relative;
}

.ranking-score-rank-img {
    border-radius: 50%;
    border: 6px solid red;
    width: 8vh;
    height: 8vh;
    pointer-events: none;
}

.ranking-score-rank,
.ranking-score-rank-top {
    display: grid;
    grid-template-columns: 2fr 2fr 15fr;
    gap: 24px;
    align-items: center;
    background-color: white;
    border: 2px solid #F1F1F6;
    border-radius: 18px;
    padding: 10px;
}

.ranking-score-rank-top {
    width: 350px;
    position: relative;
}

.ranking-score-bar-container {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ranking-score-bar-description {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ranking-score-rank-name,
.ranking-score-rank-score {
    font-weight: bold;
}

.ranking-score-rank-name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 75%;
}

.ranking-score-bar-background {
    background-color: #D9D9D9;
    border-radius: 4px;
}

.ranking-score-bar {
    height: 6px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0B7AFF;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 1px 1px #000000;
}



.modal-payment {
    margin: auto;
    width: 45vw;
    text-align: center;
}

.unnotify-button {
    border: 1px solid #FFCC00;
    border-radius: 6px;
    padding-left: 5px;
    margin-top: 5px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flow-root;
    min-width: 170px;
}

.notify-button {
    border: 1px solid #9F9F9F;
    border-radius: 6px;
    padding-left: 5px;
    margin-top: 5px;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flow-root;
    padding-right: 5px;
    min-width: 170px;
}

.pin-annoucement-top {
    width: 80%;
    overflow-wrap: break-word;
    background: rgba(255, 255, 255, 0.75);
    margin: 12px;
    border-radius: 10px;
    padding: 10px;
    cursor: default;
    position: absolute;
    top: 5px;
}

.pin-announcement-content {
    margin-bottom: 5px;
}

.pin-announcement-text {
    display: inline;
    font-size: 13px;
}

.pin-icon {
    max-width: 15px;
}

.close-icon {
    max-width: 20px;
    display: inline;
    float: right;
    cursor: pointer;
    margin-right: 10px;
}

.pin-announcement-description {
    font-size: 11px;
}

.video-react-fullscreen-control {
    margin-left: auto !important;
}



@media (max-width: 1280px) {
    .ranking-score-top-container {
        max-width: 100%;
    }

    .ranking-score-student-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 960px) {
    .perfect-score-board {
        width: 100%;
        left: auto;
        min-height: auto;
        max-height: auto;
        overflow-y: auto;
    }

    .perfect-score-rank-name {
        font-size: smaller;
    }

    .ranking-score-board {
        width: 100%;
        left: auto;
        top: 15%;
        height: initial;
        transform: initial;
        min-height: auto;
        max-height: auto;
        overflow-y: auto;
    }
}

@media screen and (max-width: 768px) {
    .ranking-score-top-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .ranking-score-rank-top {
        width: 100%;
    }

    .ranking-score-student-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .LS-comment {
        top: 9px;
        left: 48px;
    }

    .LS-viewers {
        top: 9px;
        left: 3px;
    }
}

@media (max-width: 576px) {
    .chat-content {
        height: 120vh;
        background: #e9e9f2;
    }
}