.bg-blue {
    background: #0D7BFF;
    color: #ffffff;
}

.exam-component {
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
    background-image: url("../../images/background/mask-group.png");
    background-size: cover;
    min-height: 250px;
}

.exam-container {
    background: #F1F7F5;
    border-radius: 12px;
    color: black;
    white-space: normal;
    padding: 20px 15px 20px 15px;
    cursor: pointer;
    margin-bottom: 10px;
}

.exam-list-container {
    border: 2px solid #34B37E;
    border-radius: 10px;
    padding: 7px 1px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.exam-list-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 10px;
}

.exam-list-title {
    margin: 0;
    font-size: 30px;
}

.exam-list-buttons {
    display: flex;
}

.exam-arrow {
    width: 5vh;
}

.exam-arrow-mini {
    display: none;
}

.exam-list-checkmark {
    width: 15px;
    height: auto;
    margin: auto 6px 2px 2px;
}

.exam-list-tag {
    font-size: clamp(12px, 1vw, 14px);
    color: #EFFFFB;
    text-align: left;
    overflow-wrap: break-word;
    padding: 0.15vw 0.45vw;
    margin-right: 5px;
    border-radius: 20px;
    background: #34B37E;
    position: relative;
    top: 1px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.exam-list-container .video-tag-status-image {
    width: 32px;
    height: 22px;
    margin-right: -6px;
}

.exam-subject-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

#exam-list-button-bottom {
    display: none;
}

@media (max-width: 1200px) {
    .exam-arrow-mini {
        display: flex;
    }

    .exam-arrow-large {
        display: none;
    }
}

@media (max-width: 575px) {
    .bg-blue {
        border-radius: 0;
    }

    .exam-component {
        align-items: center;
    }

    .exam-list-container {
        padding: 9px 1px 11px;
        gap: 5px;
        border-right: none;
        border-left: none;
    }

    .exam-list-checkmark {
        margin-left: 0px;
        margin-right: 7px;
        margin-bottom: 4px;
    }

    .exam-list-container .video-tag-status-image {
        width: 45px;
        height: auto;
        margin-left: -12px;
    }

    .exam-items-container {
        padding: 0;
    }

    .exam-container {
        margin: 5%;
    }

    .exam-list-header-container {
        padding-top: 20px;
        justify-content: center;
    }

    .exam-name {
        margin-top: 10px;
        font-size: 18px;
        line-height: 1.5;
    }

    .exam-list-tag {
        padding: 4px 6px;
        margin: 0;
        font-size: 16px;
        border-radius: 10px;
    }

    .exam-subject-tags-container {
        justify-content: end;
        gap: 4px;
    }

    .exam-subject-tag {
        padding: 4px 8px;
        font-size: 16px;
    }

    #exam-list-button-top {
        display: none;
    }

    #exam-list-button-bottom {
        display: inline-block;
        margin-bottom: 20px;
    }
}