.popup-bg {
    position: absolute;
    top: 15%;
    margin: auto;
    border-radius: 15px;
    width: fit-content;
    height: fit-content;
    /* background: #fff;
    box-shadow: 8px 8px 12px 0px #c1c0c0; */
    left: 0;
    right: 0;
    text-align: center;
}

.popup-img {
    filter: drop-shadow(8px 8px 12px #c1c0c0);
    /* max-width: 800px;
    max-height: 400px; */
    max-width: 50vw;
    max-height: 75vh;
    z-index: 5;
}

.popup-close {
    position: absolute;
    top: -30px;
    right: -35px;
    filter: drop-shadow(0px 0px 5px #c1c0c0);
}