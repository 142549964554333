.sidebar-card {
    background-color: white;
    margin-right: -15px;
    margin-left: -30px;
}

#filter-menu {
    position: sticky;
    top: 15px;
}

.filter-section {
    margin-left: 15px;
    margin-top: 15px;
    margin-right: 5px;
    width: 100%;
    border-bottom: 1px solid #efeff4;
}

.filter-title {
    font-size: clamp(20px, 2vw, 30px);
    font-weight: bold;
}

.filter-item-title {
    font-size: clamp(16px, 1.5vw, 24px);
    font-weight: bold;
}

.filter-item-select-card {
    display: inline-flex;
    margin: 0px 5px 10px 0px;
    padding: 5px 8px;
    border-radius: 6px;
    border: solid 1px #efeff4;
    align-items: center;
    cursor: pointer;
    background-color: white;
}

.filter-item-select-card-active {
    display: inline-flex;
    margin: 0px 5px 10px 0px;
    padding: 5px 8px;
    border-radius: 6px;
    border: solid 1px #0b77ff;
    align-items: center;
    cursor: pointer;
    background-color: #f3faff;
}

.filter-item-select-icon {
    width: 14px;
    height: 14px;
    border: 1px solid #efeff4;
    border-radius: 4px;
    background-color: white;
}

.filter-item-select-icon-active {
    width: 14px;
    height: 14px;
    border: 1px solid #0b77ff;
    border-radius: 4px;
    background-color: #0b77ff;
}

.filter-item-select-title {
    margin-left: 8px;
    font-size: clamp(12px, 1vw, 15px);
}

.premium-regis {
    margin: 10px 15px 10px auto;
    padding: 10px 10px 12px;
    background: #f3b940;
    box-shadow: 0px 0px 20px rgba(255, 204, 0, 0.3);
    border-radius: 10px;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
}

.premium-annouce {
    max-width: 250px;
    width: 95%;
}