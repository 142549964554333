.question-bg {
    position: absolute;
    z-index: 5;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 15px;
    width: 100%;
    max-width: 800px;
    max-height: 400px;
    height: 100%;
    background: #fff;
    box-shadow: 8px 8px 12px 0px #c1c0c0;
}

.question-text {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding: 0px 15px;
    font-size: 0.9vw;
    word-break: break-word;
    white-space: pre-line;
}

.question-name-layout {
    border-radius: 12px;
    background-color: #efeff4;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
}

.number-question {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #0077ff;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    border-radius: 100%;
    text-align: center;
    top: 3%;
    left: 3%;
}

.check-quiz {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.answer {
    height: 10%;
}

.answer-text:hover {
    background-color: #ccc;
}

.quiz-close {
    position: absolute;
    top: 2%;
    right: 2%;
}

.image-choice-layout {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 14px;
    background-color: #ffffff;
}

.image-choice {
    height: 120px;
    border-radius: 12px 12px 12px 12px;
    z-index: 1;
    object-fit: contain;
    border: solid 1px #efeff4;
}

.question-image {
    text-align: center;
}