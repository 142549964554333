.mobile-pay-bg-sign-up {
    border-radius: 20px;
    background-color: #ffffff;
    animation: pop 0.3s;
    max-width: 465px;
    height: 80%;
    margin: auto;
    overflow: scroll;
    overflow-x: hidden;
    padding: 10px 10px 100px 10px;
}

.mobile-pay-header {
    text-align: center;
}

.mobile-pay-input-signup {
    margin: 8px 0;
}

.mobile-pay-input-signup img {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 0;
    bottom: 0;
    left: 25px;
    margin: auto;
}

.mobile-pay-input-signup input {
    width: 100%;
    height: 50px;
    padding-left: 34px;
    border-radius: 12px;
    border: solid 1px #efeff4;
    background-color: #ffffff;
}

.mobile-pay-input-signup select {
    width: 100%;
    height: 50px;
    padding-left: 34px;
    border-radius: 12px;
    border: solid 1px #efeff4;
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: black;
}

.mobile-pay-input-signup .drop {
    left: unset !important;
    right: 5% !important;
    width: 13px !important;
    height: 13px !important;
    margin: auto 15px;
}

.otp-request-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

#otp-request-button {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

#otp-request-button:hover {
    background-color: #0056b3;
}

#otp-request-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.mobile-pay-otp-container {
    margin: 30px 0;
}

.mobile-pay-premium-text-payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    text-align: center;
}

.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.mobile-pay-form-payment {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 60%;
    padding-right: 30px;
    color: #0077FF;
    margin-top: 10px;
    padding: 5px 15px;
    border: 1px solid #0077FF;
    border-radius: 4px;
}

.select-arrow {
    position: absolute;
    right: 23%;
    top: 60%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 20px;
    color: #0077FF;
}

.select-wrapper::after {
    content: '';
    display: block;
    clear: both;
}

.mobile-pay-price-display {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
    margin-top: 30px;
}

#promptpay-logo {
    max-width: 50%;
    margin-bottom: 25px;
}

.mobile-pay-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.mobile-pay-qr p {
    margin-top: 10px;
}

.mobile-pay-notes {
    text-align: center;
}

.mobile-pay-notes-list {
    text-align: left;
}

.mobile-pay-requirements {
    display: flex;
    flex-direction: column;
}

.mobile-pay-accept {
    transform: scale(1.5);
    border-radius: 3px;
    border: solid 1px #efeff4;
}

.mobile-pay-register-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#mobile-pay-register-button {
    width: 75%;
    height: 50px;
    border-radius: 12px;
    background-color: #f97d5e;
    font-family: HelveticaNeue;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}