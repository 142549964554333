.notice-board-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b7aff;
    padding: 10px;
}

.notice-board-megaphone {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 8px;
}

.notice-board-text {
    max-width: 50%;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-top: auto;
    margin-bottom: auto;
    white-space: pre-line;
}

.notice-board-close-button {
    cursor: pointer;
    margin-left: auto;
}

@media only screen and (max-width: 960px) {
    .notice-board-text {
        max-width: 60vh;
        font-size: 13px;
    }
}

@media only screen and (max-width: 575px) {
    .notice-board-container {
        flex-direction: column-reverse;
    }

    .notice-board-megaphone {
        display: none;
    }

    .notice-board-text {
        font-size: 12px;
    }

    .notice-board-close-button {
        margin: -16px -12px -12px auto;
    }
}