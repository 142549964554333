.show-bg {
    background-color: #E9E9F2;
    /* padding: auto auto auto auto; */
    padding: 20px 100px 20px 100px;
}

.list-bg {
    border-radius: 20px;
    background-color: #FFFFFF;
}

.view-all-history {
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 10px;
    right: 60px;
    top: 1420px;
    background: #EFEFF4;
    border-radius: 12px;
    margin-right: 15px;
    display: inline-block;
    text-align: center;
}

.form-history {
    background-color: #EFEFF4;
    border: none;
    text-align: left;
    color: #0077FF;
}


/* #root {
    background-color: #E9E9F2;
}

#headerbar {
    background-color: #FFFFFF;
} */

.topic-name-list {
    font-size: clamp(12px, 1vw, 14px);
}

.live-stream-table-list-title {
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
}

.live-stream-item {
    /* margin-left: 15px; */
    height: auto;
    display: inline-block;
}

.live-stream-item p {
    white-space: break-spaces;
}

.live-stream-item:last-child {
    margin-right: 15px;
}

.live-stream-item-image {
    padding: 4px;
}

.live-stream-item-selected {
    width: 100%;
    height: 100%;
    top: 0%;
    position: absolute;
    border-radius: 10px;
    padding: 6px;
}

.live-stream-item-unselected {
    width: 100%;
    height: 100%;
    top: -100%;
    position: relative;
    padding: 10px;
}

.live-stream-item-playing-text {
    /* width: 50px; */
    height: 20px;
    border-radius: 23px;
    border: solid 1px #d1d1d6;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    padding: 2px 5px 2px 5px;
    justify-content: center;
    align-items: center;
}

.live-stream-item-broadcasting-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.live-stream-item-broadcasting-text {
    /* width: 20px; */
    height: 20px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    padding: 2px 5px 2px 5px;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.is-live {
    margin-left: 5px;
}

.livestream-schedule-table-sub-title {
    font-size: clamp(13px, 1.2vw, 20px);
    padding-left: 15px;
}

.livestream-schedule-scroll-left-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
    margin-right: 4px;
    transform: rotate(180deg);
}

.livestream-schedule-scroll-right-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
}

.livestream-schedule-scroll-button-container {
    width: clamp(60px, 7vw, 100px);
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
}

.topic-name-ls {
    white-space: break-spaces;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.class-name {
    overflow-wrap: break-word;
    color: #000000;
    font-size: clamp(10px, 1.2vw, 16px);
    padding-right: 10px;
    white-space: break-spaces;
}

.view-all {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* padding: 2px 10px; */
    /* width: 104px; */
    /* height: 44px; */
    right: 60px;
    top: 1420px;
    background: #EFEFF4;
    border-radius: 12px;
    width: clamp(60px, 7vw, 100px);
    margin-right: 15px;
    display: inline-block;
    text-align: center;
    /* justify-content: flex-end; */
}

.view-all-arrow {
    position: absolute;
    left: 69.23%;
    right: 9.62%;
    top: 36.36%;
    bottom: 36.36%;
    background: #0077FF;
}

.video-type {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 10px;
    position: absolute;
    width: 52px;
    height: 25px;
    right: 1290px;
    top: 1554px;
}

.livestream-date {
    left: 19.01%;
    right: 66.67%;
    top: 52.71%;
    bottom: 46.73%;
    font-style: normal;
    font-size: clamp(12px, 1.2vw, 16px);
    line-height: 25px;
    color: #8E8E93;
    margin-bottom: 0px;
}

.subject-name-ls {
    padding: 2px 4px;
    margin-bottom: 0px;
    color: #8e8e93;
}

.video-tag-group {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
}

.video-type-premium {
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 10px 0px 0px;
    position: absolute;
    height: 25px;
    right: 1290px;
    top: 1554px;
}

#premium {
    padding-top: clamp(1px, 0.15vw, 5px);
    padding-bottom: clamp(1px, 0.15vw, 5px);
    padding-left: clamp(6px, 0.3vw, 9px);
    padding-right: clamp(6px, 0.3vw, 9px);
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: #ffffff;
    font-weight: bold;
    border-radius: 4px;
    background-color: #FFCC00;
    font-size: clamp(12px, 1vw, 14px);
    text-align: center;
}

.video-ranking {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    position: absolute;
    right: 91%;
    bottom: 84%;
    border-radius: 50%;
    display: inline-block;
    width: 12%;
    height: 21%;
    background: #FFCC00;
    box-shadow: 0px 0px 10px rgba(255, 204, 0, 0.4);
    font-style: normal;
    font-weight: bold;
    font-size: clamp(18px, 1.2vw, 24px);
    line-height: 33px;
    color: #FFFFFF;
}

.video-ranking-low {
    background: #0077FF;
    box-shadow: 0px 0px 10px rgba(0, 119, 255, 0.4);
}

.LSH-duration {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: white;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0px 5px;
    border-radius: 5px;
}

.watched-ls {
    position: absolute;
    bottom: 5px;
    padding: 0px 5px;
    border-radius: 5px;
}

/* @media (max-width: 575px) {
    .live-stream-item {
        margin-left: 6vw;
    }
} */