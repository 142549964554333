.pay-mobile-image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.pay-mobile-image-upload input[type="file"] {
    display: none;
}

.pay-mobile-image-upload label {
    padding: 10px 20px;
    background-color: #f97d5e;
    color: #ffffff;
    border: 2px solid #f97d5e;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.pay-mobile-image-upload label {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #4a90e2;
    color: #ffffff;
    border: 2px solid #4a90e2;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.pay-mobile-upload-icon {
    margin-left: 10px;
    width: 20px;
    object-fit: contain;
}

#payMobileFileName {
    margin-top: 10px;
    font-size: 14px;
    color: #666666;
}

.pay-mobile-messenger-container {
    display: flex;
    justify-content: center;
}

.pay-mobile-message-us-button {
    padding: 10px 20px !important;
    background-color: #ffffff;
    color: #006bbf;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #006bbf;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75% !important;
}

.pay-mobile-message-us-button:hover {
    background-color: #006bbf;
    color: #ffffff;
}

.pay-mobile-line-us-button {
    padding: 10px 20px !important;
    background-color: #ffffff;
    color: #28a745;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #28a745;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75% !important;
}

.pay-mobile-line-us-button:hover {
    background-color: #28a745;
    color: #ffffff;
}

.mobile-pay-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 30px 0 20px;
}

.mobile-pay-category-button {
    padding: 12px 28px;
    border: 1px solid transparent;
    background-color: #f97d5e;
    color: white;
    cursor: pointer;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease, color 0.3s ease;
    position: relative;
    overflow: hidden;
}

.mobile-pay-category-button.active {
    background-color: #f97d5e;
    color: white;
}

.mobile-pay-category-button:not(.active) {
    background-color: #f9b79e;
    color: #a63f2a;
    box-shadow: none;
}

.mobile-pay-category-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: transform 0.6s ease;
    border-radius: 50%;
}

.mobile-pay-category-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(249, 125, 94, 0.5);
}

#addressAppend {
    background-color: white !important;
    margin-bottom: 10px;
}

#parentPhone {
    margin-top: 10px;
}