.img-topic {
    border-radius: 20px;
    width: 50%;
    /* margin: 10px; */
    height: 50%;
    margin-left: 10px;
    height: 50%;
    margin-top: 20px;
    cursor: pointer;
}

.date-topic {
    height: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #8E8E93;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
}

.name-topic {
    /* width: 520px; */
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 120%;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
}

.detail-topic {
    /* width: 520px; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #8E8E93;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin-top: 10px;
    word-break: break-word;
    white-space: break-spaces;
    cursor: pointer;
}

.all-video {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: #000000;
    margin: 10px;
}

.topic-name-list-topic {
    font-size: clamp(12px, 1vw, 20px);
}

.content-ls-playback-detail {
    display: inline-flex;
}

.content-ls-playback-detail {
    display: inline-flex;
}

.img-content-ls-playback {
    display: contents;
}

@media (max-width: 1024px) {
    .content-ls-playback-detail {
        display: contents;
    }
    .name-topic {
        font-size: 20px;
    }
    .detail-topic {
        font-size: 12px;
    }
    .img-topic {
        width: 90%;
    }
    .img-content-ls-playback {
        text-align: center;
    }
}