.view-all-recent {
    background: #EFEFF4;
    border-radius: 12px;
    padding: 0px 25px;
    margin-right: 15px;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s ease;
}

.view-all-recent:hover {
    background: #CCCCCC;
}

.view-all-text {
    position: static;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #0077FF;
    margin: 11.5px auto;
}

.lsList-item {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.video-duration-image-lsList {
    width: 10px;
    height: auto;
    margin-right: 8px;
    margin-left: 4px;
}

.lsList-header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 6px;
    row-gap: 5px;
}

.topic-name-ls-list {
    margin: 0;
}

.lsList-time span {
    margin-right: 6px;
}

.lsList-recent-buttons-container {
    display: flex;
}

.lsList-scroll-button {
    width: 44px;
    height: 44px;
}

.lsList-container {
    margin-top: 2%;
    margin-left: 15px;
}

.lsList-title {
    margin-top: 5%;
    margin-bottom: 2%;
}

.lsList-tag-container {
    margin-top: 5px;
    margin-bottom: 5px;
    row-gap: 5px;
}

.lsList-time {
    padding: 4px;
}

@media (max-width: 991px) {
    .lesson-list-scroll-button-container {
        display: none;
    }
}