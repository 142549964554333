.payment-history-list-container {
    padding-bottom: 15px;
}

.payment-history-list-titie {
    font-size: clamp(16px, 1.3vw, 20px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.01px;
    text-align: left;
    color: #000000;
}

.payment-history-list-item-container {
    border-radius: 6px;
    border: solid 1px #e5e5ea;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.payment-history-list-item-profile-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.payment-history-list-item-profile-image {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    border: solid 1px #e5e5ea;
}

.payment-history-list-item-profile-detail-container {
    margin-left: 10px;
}

.payment-history-list-item-profile-fullname-text {
    margin: 0px 0px 0px 0px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: #000000;
}

.payment-history-list-item-profile-login-text {
    margin: 0px 0px 0px 0px;
    font-size: clamp(12px, 1vw, 15px);
    color: #8e8e93;
}

.payment-history-list-item-transaction-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e5e5ea;
}

.payment-history-list-item-transaction-date-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    color: #202124;
}

.payment-history-list-item-transaction-pending-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: #ffcc00;
    text-decoration: underline;
    cursor: pointer;
}

.payment-history-list-item-transaction-pending-text:hover {
    color: #007AFF;
}

.payment-history-list-item-transaction-confirm-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: #ffcc00;
}

.payment-history-list-item-transaction-success-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: #4cd964;
}

.payment-history-list-item-transaction-danger-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: red;
}

.payment-history-list-item-transaction-gray-text {
    margin: 5px 5px 5px 5px;
    font-size: clamp(12px, 1vw, 15px);
    font-weight: bold;
    color: gray;
}

.payment-history-list-empty-item {
    width: 100%;
    height: 100%;
    text-align: center;
}

.open-receipt-button {
    cursor: pointer;
}

.cancel-confirm-custom-ui {
    text-align: center;
    /* width: 550px; */
    padding: 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    margin: 50px;
}

.cancel-confirm-custom-ui>h1 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 32px;
}

.cancel-confirm-custom-ui>p {
    margin: 0;
}

.cancel-confirm-custom-ui>button {
    width: 160px;
    padding: 10px;
    margin: 20px;
}

.payment-history-pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.payment-history-list-item-product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.payment-history-list-item-product-description,
.payment-history-list-item-amount-text {
    margin: 0px 0px 0px 0px;
    font-size: clamp(12px, 1vw, 15px);
}

@media (max-width: 767.5px) {
    .cancle-button-payment-history {
        margin-top: 10px;
    }
}