.edit-profile-container {
    margin-left: 30%;
    margin-right: 30%;
    padding-top: 20px;
    padding-bottom: 60px;
}

.edit-profile-title {
    text-align: center;
    font-weight: bold;
    font-size: clamp(1rem, 8vw, 2rem);
}

.save-edit-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.save-edit-profile-container p {
    color: red;
    font-weight: bold;
}

.save-edit-profile-button {
    /* width: 40%; */
    background-color: #0b7aff;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 12px;
}

.edit-profile-image-container {
    display: flex;
    justify-content: center;
}

.email-notification-div {
    text-align: center;
    border: 2px dashed gainsboro;
    border-radius: 30px;
    padding-bottom: 1rem;
    margin: 2rem auto auto auto;
}

@media (max-width: 1020px) {
    .edit-profile-container {
        padding-bottom: 30vh;
    }
}

@media (max-width: 740px) {
    .edit-profile-container {
        margin: auto 15vw;
    }
}