.livestream-page-container {
    width: 100%;
    height: 100%;
    margin: 0;
}

.video-section-container {
    width: 100%;
    height: 100%;
    background-color: #f7f7fc;
}

.live-title-container {
    padding: 15px;
}



.history-questions {
    display: flex;
    gap: 15px;
    overflow-x: auto;
}

.history-question-button {
    background-color: #f0f0f5;
    border: solid 1px #f0f0f5;
    border-radius: 12px;
    padding: 12px 24px;
    margin-bottom: 24px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #000000;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.history-question-button.active {
    background-color: #0b7aff;
    color: #ffffff;
}

.history-question-button:hover {
    background-color: #d5d5d5;
    color: #333333;
}

.history-question-button:focus {
    outline: none;
}

.history-question-button:active {
    background-color: #0b7aff;
    color: #ffffff;
    transform: scale(0.8);
}



.document-title-header-container {
    margin-top: clamp(20px, 1.7vw, 30px);
}

.document-title-header {
    font-size: clamp(20px, 1.7vw, 30px);
    font-weight: bold;
    line-height: 0.73;
}

.document-download-container {
    margin-top: clamp(14px, 1.3vw, 20px);
}

.document-download-button {
    width: 100%;
    height: clamp(60px, 6.5vw, 100px);
    border-radius: 20px;
    border: solid 1px #efeff4;
    background-color: #fcfcfd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: clamp(14px, 1.3vw, 20px);
    cursor: pointer;
}

.document-thumbnail-icon {
    width: clamp(40px, 4vw, 60px);
    height: clamp(40px, 4vw, 60px);
}

.document-download-text {
    font-size: clamp(14px, 1.3vw, 20px);
    font-weight: bold;
}

.document-download-icon {
    width: clamp(30px, 3vw, 40px);
    height: clamp(30px, 3vw, 40px);
}

.modal-payment {
    margin: auto;
    width: 45vw;
    text-align: center;
}