.join-school-title {
  font-family: HelveticaNeue;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: -0.01px;
  text-align: center;
  color: #000000;
}

.join-school-name {
  font-family: HelveticaNeue;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.01px;
  text-align: center;
  color: #000000;
}

.join-button {
  width: 450px;
  height: 50px;
  border: none;
  border-radius: 12px;
  background-color: #ffcc00;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.join-button-complete {
  width: 450px;
  height: 50px;
  border: none;
  border-radius: 12px;
  background-color: #4cd964;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.join-profile-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: solid 2px #ffcc00;
  background-color: #ffffff;
}

.join-input-filter {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  height: 44px;
  padding-left: 10px;
  border-radius: 12px;
  border: solid 1px #d1d1d6;
  background-color: #efeff4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
}

.join-input-filter-drop{
  position: absolute;
  margin-left: -20px;
  margin-top: 20px;
}