.address-modal-modal {
    max-width: 100%;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.address-modal-header {
    background-color: #f5f6f7;
    border-bottom: 2px solid #e0e0e0;
    padding: 20px;
    text-align: center;
    font-weight: 500;
}

.address-modal-title {
    font-size: 1.5rem;
    color: #333;
}

.address-modal-body {
    padding: 25px 20px;
    max-height: 500px;
    overflow-y: auto;
    background-color: #fafafa;
}

.address-modal-scrollable-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 12px;
    background-color: #fff;
}

.address-modal-clickable-item {
    padding: 14px 20px;
    cursor: pointer;
    font-size: 1.1rem;
    color: #555;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border-bottom: 1px solid #eee;
}

.address-modal-clickable-item:last-child {
    border-bottom: none;
}

.address-modal-clickable-item:hover {
    background-color: #f0f1f3;
    transform: translateX(5px);
}

.address-modal-clickable-item.active {
    background-color: #e6eff9;
}

.address-modal-h5 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.address-modal-clickable-item {
    padding: 14px 20px;
    cursor: pointer;
    font-size: 1.1rem;
    color: #333;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.address-modal-clickable-item:hover {
    background-color: #fff3e3;
    transform: scale(1.02);
}

.address-modal-clickable-item.active {
    background-color: #0d6efd;
    color: white;
    border: 1px solid #0a58ca;
}

.address-modal-step-4 {
    display: flex;
    align-items: center;
}

.address-modal-step-4 h5 {
    margin: 0 20px 0 10px;
}

.address-modal-step-4 input {
    width: initial;
    margin: 0;
}

.address-modal-input {
    width: 100%;
    padding: 12px 18px;
    font-size: 1.1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.2s ease;
}

.address-modal-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
}

.address-modal-footer {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f6f7;
    border-top: 2px solid #e0e0e0;
    font-size: 1rem;
}

.address-modal-btn {
    padding: 12px 25px;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    min-width: 120px;
}

.address-modal-btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
}

.address-modal-btn-primary:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.address-modal-btn-light {
    background-color: #f5f6f7;
    color: #007bff;
    border: 1px solid #ddd;
}

.address-modal-btn-light:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}

.address-modal-footer button {
    margin-left: 12px;
}

.address-modal-footer::after {
    content: "";
    display: table;
    clear: both;
}

.address-modal-input:focus,
.address-modal-btn:hover {
    transform: translateY(-2px);
}

@media (max-width: 768px) {
    .address-modal-step-4 {
        display: block;
        align-items: flex-start;
    }

    .address-modal-step-4 h5 {
        margin: 0;
    }

    .address-modal-step-4 input {
        width: 100%;
        margin: 10px 0;
    }
}