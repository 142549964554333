.mobile-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0 200px 60px;
}

.artwork {
    max-width: 70%;
    width: auto;
    height: auto;
    margin: 10px;
}

.video-container {
    position: relative;
    width: 90%;
    margin: 10px 0;
    padding-bottom: 56.25%;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.mobile-features {
    display: flex;
    flex-direction: column;
}

.mobile-price-plan-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 20px;
}

.mobile-price-plan-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mobile-plan-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
}

.mobile-plan-subtitle {
    font-size: 1.2rem;
    color: #333;
    margin: 10px 0 20px;
    line-height: 1.5;
    text-align: center;
}

.mobile-price-plan-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mobile-price-plan-column li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.mobile-price-plan-column label {
    font-size: 16px;
    color: #333;
    cursor: default;
}

.mobile-checkbox-list {
    display: flex;
    align-items: center;
    padding: 20px 40px;
    font-family: 'Helvetica Neue', sans-serif;
    border-radius: 10px;
    margin: 0 auto;
    gap: 40px;
}

.mobile-checkbox-list ul {
    padding: 0;
    margin: 0;
}

.mobile-checkbox-list li {
    display: flex;
    width: 300px;
    margin-bottom: 12px;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
}

.mobile-checkbox-list li:last-child {
    border-bottom: none;
}

.mobile-checkbox-list input[type="checkbox"] {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    pointer-events: none;
    position: relative;
}

.mobile-checkbox-list label {
    font-size: 16px;
    color: #333;
    cursor: default;
    pointer-events: none;
}

.mobile-empty-checkbox {
    padding: 0 4px;
    margin-right: 8px;
}

.mobile-pay-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #f97d5e;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.mobile-pay-button:hover {
    background-color: #e86e4c;
}

.mobile-checkbox-button-container {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1024px) {
    .mobile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        padding: 0 0 40%;
    }

    .artwork {
        max-width: 90%;
        width: auto;
        height: auto;
        margin: 10px;
    }

    .video-container {
        position: relative;
        width: 90%;
        margin: 10px 0;
        padding-bottom: 56.25%;
        height: 0;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .mobile-features {
        display: flex;
        flex-direction: column;
    }

    .mobile-pay-starting-price {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        margin-top: 20px;
        text-align: center;
        padding: 10px;
        border-radius: 5px;
    }

    .mobile-checkbox-list {
        background-color: #f9f9f9;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .mobile-checkbox-list li {
        width: 250px;
    }

    .mobile-pay-button {
        font-weight: bold;
    }
}