.question-bg {
    position: fixed;
    z-index: 5;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 15px;
    max-width: 800px;
    max-height: 400px;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 8px 8px 12px 0px #c1c0c0;
}

@media screen and (max-width: 768px) {
    #quiz-container {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #question-container {
        max-width: 100%;
        height: 50% !important
    }

    #answer-container {
        max-width: 100%;
        overflow-y: scroll;
    }

    .answer-image-container {
        justify-content: center;
    }

    #question-side-close {
        visibility: visible;
    }

    #answer-side-close {
        visibility: hidden;
    }
}

@media screen and (min-width: 769px) {
    #question-side-close {
        visibility: hidden;
    }

    #answer-side-close {
        visibility: visible;
    }
}

.question-text {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    padding: 0px 15px;
    font-size: 90%;
    word-break: break-word;
    white-space: pre-line;
}

.question-text-alone {
    font-size: 125%;
}

.question-image {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.question-image img {
    max-width: 95%;
    max-height: 95%;
}

.question-name-layout {
    border-radius: 12px;
    background-color: #efeff4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
}

.question-name-layout>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-y: scroll;
}

.number-question {
    position: absolute;
    background-color: #0077ff;
    font-size: 1vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 100%;
    text-align: center;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-question p {
    margin: 0px;
}

.check-quiz {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: white;
}

.answer {
    height: 10%;
}

.answer-text:hover {
    background-color: rgb(238, 238, 238);
    border-radius: 6px;
}

.quiz-close {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 2;
}

.image-choice-layout {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 14px;
    background-color: #ffffff;
}

.image-choice-layout .answer-percentage-text {
    top: 6%;
    right: 8%;
}

.image-choice {
    height: 120px;
    border-radius: 12px 12px 12px 12px;
    z-index: 1;
    object-fit: contain;
    border: solid 1px #efeff4;
    background-color: white;
    margin-top: 15px;
}

.answer-container {
    position: relative;
    width: 90%;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

#answer-container-1 {
    overflow-y: scroll;
}

#answer-container-2 {
    height: auto !important;
}

.answer-container.image {
    margin-left: 0;
}

.answer-percentage-container {
    position: absolute;
    width: 82%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    margin-left: 18%;
}

.answer-text-container {
    display: flex;
    align-items: center;
    z-index: 1;
    font-size: small;
}

.answer-image-container {
    display: flex;
    align-items: center;
    z-index: 1;
    font-size: small;
}

.answer-percentage-text {
    position: absolute;
    height: 100%;
    z-index: 2;
    right: 0;
}

.answer-choice-container {
    width: 100%;
    display: flex;
    padding: 2% 2% 2% 2%;
    z-index: 1;
}

.loading-submit-layout {
    position: absolute;
    width: 85%;
    height: 100%;
    top: 0;
    display: flex;
    background: #ffffff;
    z-index: 1;
}

.choice-default {
    margin: 0 15px 0 0;
    padding: 8px 20px;
    border-radius: 6px;
    border: solid 1px #efeff4;
    background-color: #ffffff;
    font-weight: bold;
    color: #000000;
}

.choice-default.incorrect {
    background-color: #fe3b30;
    color: #ffffff;
}

.choice-default.correct {
    background-color: #19b71f;
    color: #ffffff;
}

.choice-default.choice {
    background-color: #c7c7cc;
    color: #ffffff;
}

.spinner-border {
    margin: auto;
}

.live-quiz-image-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.live-quiz-image-popup-content {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    max-width: 95%;
    max-height: 90%;
    overflow: hidden;
}

.live-quiz-image-popup img {
    width: 100%;
    height: auto;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}