#container {
    display: flex;
    flex-wrap: wrap;
}

.lesson-list-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2%;
}

.continue-watch-container {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    /* white-space: nowrap; */
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 15px;
    scroll-behavior: smooth;
    display: flex;
}

.continue-watch-container::-webkit-scrollbar {
    display: none;
}

.item {
    padding: 0px;
    cursor: pointer;
    display: inline-block;
    margin-right: 15px;
}

.video-image-container {
    position: relative;
}

.video-image-container-temp {
    width: 300px;
    height: 170px;
}

.video-image {
    width: 100%;
    height: 100%;
    border-radius: 0.5vw;
    border: solid 2px #e9e9f2;
}

.video-tag-container {
    width: 100%;
    display: flex;
    margin-top: 8px;
}

.video-tag-status-container {
    display: flex;
    align-items: center;
}

.video-tag-status-image {
    width: clamp(32px, 3vw, 36px);
    height: auto;
    margin-right: 0.2vw;
    background-color: white;
    border-radius: 5px;
}

#free {
    padding-top: clamp(1px, 0.15vw, 5px);
    padding-bottom: clamp(1px, 0.15vw, 5px);
    padding-left: clamp(6px, 0.3vw, 9px);
    padding-right: clamp(6px, 0.3vw, 9px);
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: #ffffff;
    font-weight: bold;
    border-radius: 4px;
    background-color: #0077ff;
    font-size: clamp(12px, 1vw, 14px);
    text-align: center;
    margin-right: clamp(2px, 0.2vw, 5px);
}

.video-tag-info {
    font-size: clamp(12px, 1vw, 14px);
    text-align: left;
    padding-top: 0.15vw;
    padding-bottom: 0.15vw;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
    margin-right: 0.2vw;
    color: #8e8e93;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 4px;
    border: solid 1px #efeff4;
    display: flex;
    align-items: center;
}

.topic-name {
    overflow-wrap: break-word;
    margin-top: 4px;
    line-height: 1.25;
    color: #000000;
    font-size: clamp(13px, 1.2vw, 20px);
    font-weight: bold;
    padding-right: 10px;
    white-space: break-spaces;
}


/* loading style */

.loading-header {
    height: 35px;
    border-radius: 8px;
}

.gradient-loading {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
    position: relative;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}

.lesson-list-title {
    font-size: clamp(18px, 2vw, 30px);
    margin-top: 5%;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
}

.lesson-list-sub-title {
    font-size: clamp(13px, 1.2vw, 20px);
    margin: 0px;
    max-width: 60vw;
}

.video-tag-playlist-image {
    width: clamp(10px, 0.7vw, 12px);
    height: auto;
    margin-right: clamp(2px, 0.2vw, 5px);
}

.video-duration-image {
    width: clamp(9px, 0.6vw, 11px);
    height: auto;
    margin-right: clamp(2px, 0.2vw, 5px);
}

.item-video {
    padding-right: 15px;
}

.item-video:first {
    padding-left: 0px;
    padding-right: 7.5px;
}

.lesson-list-scroll-left-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
    margin-right: 4px;
    transform: rotate(180deg);
}

.lesson-list-scroll-right-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
}

.lesson-list-scroll-button-container {
    width: clamp(60px, 7vw, 100px);
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
}