.announcement-card {
    margin-top: 2%;
    background-color: white;
    border-radius: 1vw;
    padding: 20px 0px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%);
    width: 100%;
}

.announcement-scrollable-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.announcement-item {
    border-radius: 1vw;
    display: inline-block;
    margin: auto;
}

.announcement-left-button {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px;
}

.announcement-right-button {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px;
}

.announcement-left-icon {
    width: 18px;
    height: 30px;
    transform: rotate(180deg);
}

.announcement-right-icon {
    width: 18px;
    height: 30px;
}

.announcement-item-image {
    padding: 0px 10px;
    max-width: 100%;
}

.announcement-scroll-index-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.announcement-scroll-index-point {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
    background-color: #d1d1d6;
    border-radius: 100%;
}

.announcement-scroll-index-point-active {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
    background-color: black;
    border-radius: 100%;
}

@media only screen and (max-width: 575px) {
    .announcement-item {
        margin: auto;
    }

    .announcement-left-button,
    .announcement-right-button {
        display: none;
    }
}