.mobile-pay-checkbox-list {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    text-align: initial;
}

.mobile-pay-checkbox-list ul {
    padding: 0;
    margin: 0;
}

.mobile-pay-checkbox-list li {
    display: flex;
    margin-bottom: 12px;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
}

.mobile-pay-checkbox-list li:last-child {
    border-bottom: none;
}

.mobile-pay-checkbox-list input[type="checkbox"] {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    pointer-events: none;
    position: relative;
}

.mobile-pay-checkbox-list label {
    font-size: 16px;
    color: #333;
    cursor: default;
    pointer-events: none;
}

.mobile-pay-empty-checkbox {
    padding: 0 4px;
    margin-right: 8px;
}

.mobile-pay-address {
    margin-bottom: 30px;
}

.mobile-pay-address-dropdown-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.mobile-pay-product-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 20px;
    border-radius: 8px;
}

.mobile-pay-product-item {
    width: 100%;
    padding: 12px 24px;
    border: 2px solid #e8e8e8;
    border-radius: 6px;
    cursor: pointer;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    transition: border-color 0.3s, background-color 0.3s;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.mobile-pay-product-item.selected {
    border-color: #f97d5e;
}

.mobile-pay-product-item:hover {
    border-color: #f97d5e;
}

.mobile-pay-product-name {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.mobile-pay-product-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}

.mobile-pay-product-radio input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
}

.mobile-pay-product-radio input[type="radio"]:checked {
    accent-color: #f97d5e;
}

.mobile-pay-savings-percentage {
    font-size: 14px;
    font-weight: bold;
    color: #f3b940;
}

.mobile-pay-product-pricing {
    text-align: right;
    white-space: nowrap;
}

.mobile-pay-total-price {
    font-size: 14px;
    margin-top: 10px;
}

.mobile-pay-view-all-button {
    cursor: pointer;
    background-color: transparent;
    color: #353535;
    padding: 8px 20px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.mobile-pay-view-all-button:hover {
    background-color: #f0f0f0;
    color: #4d4d4d;
}

.mobile-pay-view-all-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(125, 125, 125, 0.5);
}

@media only screen and (max-width: 575px) {
    .mobile-pay-step-2 {
        padding: 0 !important;
    }

    .mobile-pay-premium-text-payment {
        padding: 0 !important;
    }

    .mobile-pay-product-selection {
        padding: 0;
        margin: 20px 0;
    }
}