#headerbar {
    border: solid 1px #efeff4;
}

#nockacademy-text {
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: left;
    color: #f97d5e;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

.menu {
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    margin: auto;
}

.menu-container {
    margin: 8px;
}

.menu-container p {
    height: 100%;
    display: flex;
    color: #000000;
    align-items: center;
    transition: color 0.2s;
}

.menu-container p:hover {
    color: #ffcd01
}

#login-button {
    /* width: 140px; */
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    border-radius: 12px;
    background-color: #f97d5e;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
}

#login-button:hover {
    background-color: #ff9900;
}

#search-button {
    border: solid 1px #d1d1d6;
    background-color: #efeff4;
    z-index: 0;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    border: solid 2px #f97d5e;
    background-color: #ffffff;
}

.menu-profile {
    width: 200px;
    z-index: 3;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28);
}

.menu-profile:before,
.menu-profile:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
}

.menu-profile:before {
    left: 160px;
    border: 12px solid transparent;
    border-bottom-color: #ddd;
}

.menu-profile:after {
    left: 162px;
    border: 10px solid transparent;
    border-bottom-color: #fff;
}

.menu-profile-text-container {
    line-height: 1.2;
}

.menu-profile-text-container:hover {
    background-color: #f97d5e;
}

.menu-profile-text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.32px;
    text-align: left;
    color: #000000;
    margin: 10px 10px 10px 10px;
    padding: 10px 0px 10px 0px;
}

.menu-profile-border {
    height: 1px;
    border: 1px solid #efeff4;
    ;
}

.menu-profile-text:hover {
    color: white !important;
}

.wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff00;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
}