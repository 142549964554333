.live-stream-container {
    margin-top: 2%;
    width: 100%;
}

.live-stream-broadcasting-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.live-stream-items-container {
    display: flex;
    align-items: center;
    margin: auto;
}

.live-stream-detail-title-container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.live-stream-bookmark {
    margin-bottom: 32px;
}

.live-stream-current-container {
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    cursor: pointer;
    border-radius: 1vw;
}

.live-stream-image {
    border-radius: 1vw;
    margin: auto;
    border: solid 2px #e9e9f2;
}

.live-stream-broadcasting-tag {
    color: white;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: pointer;
    padding: 2px 5px;
    z-index: 1;
}

.live-stream-broadcasting-tag img {
    margin-right: 5px;
    padding-bottom: 2px;
}

.live-stream-detail-container {
    height: 100%;
    padding: 0px clamp(20px, 3vw, 60px);
}

.live-stream-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    margin-bottom: 24px;
}

.live-stream-detail-title {
    font-size: clamp(18px, 2.2vw, 40px);
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.live-stream-detail-title-container h2 {
    margin: 0;
}

.live-stream-detail-description {
    font-size: clamp(16px, 1.4vw, 28px);
    margin: 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.live-stream-broadcasting-container {
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 5px;
}

.live-stream-broadcasting-text {
    padding-left: 10px;
    padding-right: 10px;
    height: 20px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
}

.live-stream-video-container {
    border-radius: 20px;
    overflow: hidden;
    border: solid 6px #ff0000;
    height: fit-content;
    margin: auto;
}

.live-stream-detail-date {
    font-size: clamp(14px, 1.4vw, 28px);
    color: #000000;
    display: flex;
    gap: 5%;
}

.live-stream-enter-button {
    padding: clamp(15px, 1.4vw, 30px);
    font-size: clamp(16px, 1.4vw, 28px);
    font-weight: bold;
    border: none;
    border-radius: 12px;
    background: #0b77ff;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.live-stream-enter-button a,
.live-stream-enter-button a:hover {
    text-decoration: none;
    color: inherit;
}

.live-stream-enter-button img {
    width: 4vw;
    max-width: 44px;
    min-width: 30px;
    margin-left: 6px;
}

@media screen and (max-width: 768px) {
    .live-stream-broadcasting-tag {
        display: flex;
        align-items: center;
        height: 20px;
    }

    .live-stream-broadcasting-tag img {
        height: 15px;
        padding: 0;
    }

    .live-stream-detail-container {
        height: 0%;
    }
}

@media only screen and (max-width: 575px) {
    .live-stream-container {
        margin-top: 0;
    }

    .live-stream-items-container {
        flex-direction: column;
    }

    .live-stream-detail-container {
        padding: 0;
    }

    .live-stream-details {
        gap: 10px;
        margin-top: 14px;
        margin-bottom: 0;
    }

    .live-stream-bookmark {
        margin: 0;
    }

    .live-stream-enter-button-container {
        display: none;
    }
}