#sampleVideoWrapper {
    position: absolute;
    inset: 0;
}

.sample-video-bg {
    width: 90%;
    height: fit-content;
    max-width: 90%;
    max-height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    animation: pop 0.3s;
    z-index: 5;
    position: fixed;
    inset: 0;
    margin: auto;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sample-video-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;
    width: 100%;
}



.sample-video-title {
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .sample-video-title {
        font-size: 24px;
    }
}



.sample-video-close-button {
    position: absolute;
    inset: 2% 2% auto auto;
    z-index: 2;
}

.sample-video-container {
    width: 75%;
}



.sample-video-confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
    border: 1px solid #e2e8f0;
    padding: 20px 60px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: modalAppear 0.3s ease forwards;
}

@keyframes modalAppear {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.sample-video-confirmation-modal p {
    font-size: 20px;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: center;
}

.sample-video-confirmation-modal p:first-child {
    margin-bottom: 10px;
}

.sample-video-confirmation-modal p:last-child {
    margin-bottom: 20px;
}

.sample-video-confirmation-modal-buttons {
    display: flex;
    font-size: 20px;
}

.sample-video-confirmation-modal button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 40px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 140px;
}

.sample-video-confirmation-modal button:first-child {
    background-color: #28a745;
    margin-right: 10px;
}

.sample-video-confirmation-modal button:last-child {
    background-color: #dc3545;
}

.sample-video-confirmation-modal button:last-child {
    margin-right: 0;
}

.sample-video-confirmation-modal button:hover {
    background-color: #0056b3;
}