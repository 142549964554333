.payment-history-content {
    margin: 3% 12% 3% 12%;
    background-color: #FFFFFF;
}

.payment-history-title-container {
    display: flex;
    align-items: center;
    gap: 50px;
    margin-bottom: 25px;
}

.payment-history-title {
    font-size: clamp(24px, 1.8vw, 30px);
    font-weight: bold;
    margin: 0;
}

.payment-phone-handle {
    min-height: 0px;
}

.message-us-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ffffff;
    color: #006bbf;
    text-decoration: none;
    font-size: 16px;
    border: 2px solid #006bbf;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.msg-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.message-us-button:hover {
    background-color: #006bbf;
    color: #ffffff;
}

@media (max-width: 1024px) {
    .payment-phone-handle {
        min-height: 15vh;
    }
}

@media screen and (max-width: 768px) {
    .payment-history-title-container {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 50px;
    }
}