.payment-card-list-container {
  padding-bottom: 15px;
}

.payment-card-list-titie {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.01px;
  text-align: left;
  color: #000000;
}

.payment-card-list-item-container {
  border-radius: 6px;
  border: solid 1px #e5e5ea;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.payment-card-list-item-type-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-card-list-item-info-container {
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  display: grid;
  align-items: center;
  border-left: 1px solid #e5e5ea;
}

.payment-card-list-item-info-text {
  margin: 5px 5px 5px 5px;
  font-size: 15px;
  color: #202124;
}

.payment-card-list-empty-item {
  width: 100%;
  height: 100%;
  text-align: center;
}

.delete-confirm-custom-ui {
  text-align: center;
    width: 550px;
    padding: 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
}

.delete-confirm-custom-ui > h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
}

.delete-confirm-custom-ui > button {
  width: 160px;
  padding: 10px;
  margin: 20px;
}