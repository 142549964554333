.chat-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #e9e9f2;
}

.chat-message-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scroll-behavior: smooth;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    position: relative;
    width: 100%;
    height: 100%;
}

.chat-message-container::-webkit-scrollbar {
    display: none;
}

.chat-input {
    -webkit-appearance: none;
    outline: none;
    border: none;
    border-bottom: #ccc 1px solid;
    font-size: 13px;
}

.chat-input-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    max-height: 106px;
}

.chat-input-container-item {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 11px;
    box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
}

.chat-input-maximum {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #a6a6a6;
    margin-right: 5px;
}

.chat-send-button {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.chat-message-self {
    text-align: right;
}

.chat-autoscolling-container {
    position: absolute;
    bottom: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.chat-autoscolling-button {
    cursor: pointer;
    min-width: 60px;
    padding: 5px;
    border-radius: 15px;
    background-color: #0b7aff;
    color: white;
    font-size: 12px;
    text-align: center;
}

.chat-message-item {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.chat-message-item:hover {
    background: rgb(221, 221, 221);
}

.chat-message-item-message-container {
    display: flex;
    flex-direction: column;
    width: 75em;
}

.chat-message-item-image-profile {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    margin: 6px 10px 0px 10px;
    object-fit: cover;
    border-radius: 20px;
}

.chat-message-item-username-other {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    font-weight: 100;
    color: #a6a6a6;
}

.chat-message-joined {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    font-weight: 100;
    color: #a6a6a6;
    cursor: default;
}

.chat-message-item-username-self {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    font-weight: 100;
    color: #ffffff;
}

.chat-message-item-username-admin {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    font-weight: 100;
    color: white;
}

.chat-message-item-text {
    border-radius: 10px;
    padding: 10px;
    font-size: 13px;
    text-align: left;
    width: 1em;
    flex: 1;
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.self {
    background-color: #0b7aff;
    color: #ffffff;
}

.other {
    background-color: #f0f0f5;
    color: #000000;
}

.admin {
    background-color: #FF5A5E;
    color: white;
}

.admin-logo {
    border: solid 1px #FF5A5E;
}

.chat-message-item-text .self {
    border-radius: 10px;
    background-color: #0b7aff;
}

.chat-message-item-date {
    font-size: 10px;
    font-weight: bold;
    text-align: left;
    color: #a6a6a6;
    align-self: flex-end;
    margin-left: 5px;
}

.chat-message-item-date-2 {
    display: none;
}

.chat-message-container-blur {
    filter: blur(5px);
    transition: filter 0.3s;
}

.admin-blur-tag {
    font-weight: bold;
    color: white;
    background-color: gray;
    padding: 7px 14px;
    border-radius: 6px;
    font-size: 16px;
}



@media (min-width: 576px) and (max-width: 1024px) {
    .chat-message-item-date {
        display: none;
    }

    .chat-message-item-date-2 {
        display: inline;
        font-weight: bold;
    }

    .chat-message-item-username-self,
    .chat-message-item-username-admin,
    .chat-message-item-username-other {
        display: flex;
        justify-content: space-between;
    }
}

.chat-message-item-text-container {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
}

.chat-message-item-three-dots-menu-container {
    margin-top: 10px;
}

.chat-message-item-three-dots-menu-container button {
    padding: 0px;
}

.chat-login-button {
    border: none;
    width: 200px;
    height: 40px;
    background-color: #f97d5e;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 8px;
    margin-top: 8px;
}

.chat-background {
    height: 100%;
    background: #f7f7fc;
    border-radius: 20px;
    border: solid 1px #fcfcfd;
    background-color: #f7f7fc;
    box-shadow: none;
    display: flex;
    flex-direction: column;
}

.chat-title-container {
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    margin: 10px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggle-chat-content {
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease;
    user-select: none;
    margin-right: 16px;
}

.chat-button-rotate {
    transform: rotate(45deg);
}

.chat-title-text {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    color: #000000;
    display: flex;
    justify-content: space-between;
}

.chat-annoucement {
    width: 95%;
    overflow-wrap: break-word;
    background: rgba(0, 0, 0, 0.15);
    margin: 10px auto;
    border-radius: 10px;
    padding: 10px;
    cursor: default;
}

.no-select {
    user-select: none;
}

.chat-message-item-blur {
    filter: blur(5px);
    transition: filter 0.3s;
}