    .payment-layout {
        z-index: 5;
    }

    .premium-bg {
        min-width: 350px;
        width: 35%;
        border-radius: 20px;
        background-color: #ffffff;
        animation: pop 0.3s;
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        overflow: scroll;
        overflow-x: hidden;
    }

    .premium-step-top {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .premium-step-text {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .premium-step-line {
        width: 25%;
        height: 4px;
        border-radius: 12px;
        background-color: #efeff4;
    }

    .premium-step-line.active {
        background-color: #ffcc00;
    }

    .premium-step-point {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: #efeff4;
    }

    .premium-step-point.active {
        background-color: #ffcc00;
    }

    .premium-step-text .title {
        font-family: HelveticaNeue;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.95;
        letter-spacing: 0px;
        text-align: left;
        color: #efeff4;
    }

    .premium-step-text .subtitle {
        font-family: HelveticaNeue;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0px;
        text-align: left;
        color: #efeff4;
    }

    .premium-step-text .title.active {
        background-color: #ffffff;
        color: #ffcc00;
    }

    .premium-step-text .subtitle.active {
        background-color: #ffffff;
        color: #ffcc00;
    }

    .premium-title {
        font-family: HelveticaNeue;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: -0.01px;
        text-align: left;
        color: #000000;
    }

    .premium-accept {
        border-radius: 3px;
        border: solid 1px #efeff4;
    }

    .premium-button {
        width: 420px;
        height: 50px;
        border-radius: 12px;
        background-color: #ffcc00;
        font-family: HelveticaNeue;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    #payment-cancel-button {
        color: #808080;
        background: #FFF;
    }

    .premium-button.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .loading-button-spinner {
        width: 100%;
        height: 50px;
        border-radius: 12px;
        background-color: #ffcc00;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .input-premium img {
        position: absolute;
        width: 19px;
        height: 19px;
        top: 0;
        bottom: 0;
        left: 25px;
        margin: auto;
    }

    .input-premium input {
        width: 100%;
        height: 50px;
        padding-left: 34px;
        border-radius: 12px;
        border: solid 1px #efeff4;
        background-color: #ffffff;
    }

    .input-premium-date input {
        width: 100%;
        height: 50px;
        border-radius: 12px;
        padding-left: 17px;
        border: solid 1px #efeff4;
        background-color: #ffffff;
    }

    .input-premium select {
        width: 100%;
        height: 50px;
        padding-left: 34px;
        border-radius: 12px;
        border: solid 1px #efeff4;
        background-color: #ffffff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: gray;
    }

    .premium-text {
        font-family: HelveticaNeue;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.36px;
        text-align: left;
        color: #000000;
    }

    .premium-text.bold {
        font-weight: bold;
    }

    .premium-text.green {
        color: #4cd964;
    }

    .premuim-bank-select-layout {
        border: solid 1px #f5f5f8;
        background-color: #ffffff;
        border-radius: 8px;
    }

    .premuim-bank-select {
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
    }

    .premuim-bank-select.active {
        background-color: #f5f5f8;
    }

    .premium-bank-text {
        font-family: HelveticaNeue;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }

    .premium-bank-text.bold {
        font-weight: bold;
    }

    #premium-prompt-image {
        width: 88px;
    }

    .premium-method-text {
        font-family: HelveticaNeue;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.9;
        letter-spacing: 0px;
        text-align: left;
        color: #a6a6a6;
    }

    .premium-method-text.active {
        color: #ffcc00;
    }

    .premium-method-info {
        width: 60%;
    }

    .promptpay-title {
        font-family: HelveticaNeue;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0px;
        text-align: left;
        color: #000000;
    }

    .promptpay-subtitle {
        font-family: HelveticaNeue;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8;
        letter-spacing: 0px;
        text-align: left;
        color: #000000;
        white-space: nowrap;
    }

    .premium-date-input input[type=date]::-webkit-inner-spin-button,
    .premium-date-input input[type=date]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .bg-red {
        background-color: red;
    }

    .overTop {
        z-index: 5;
        background-color: #ffffff;
    }

    .premium-date-hint {
        font-family: HelveticaNeue;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.36px;
        text-align: left;
        color: #8e8e93;
    }

    .premium-msg-button {
        border-radius: 12px;
        border: solid 2px #007aff;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .premium-msg-button img {
        width: 34px;
        height: 34px;
        margin: 8px;
    }

    #qr-promptpay {
        max-width: 120px;
        max-height: 120px;
        width: 100%;
    }

    .input-premium .drop {
        left: unset !important;
        right: 5% !important;
        width: 13px !important;
        height: 13px !important;
    }

    .payment-amount-container {
        width: 100%;
        padding-top: 1.5rem;
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .payment-amount-text {
        margin: 0;
        font-size: 32px;
    }

    .payment-amount-text-span {
        color: #FF9500;
        font-weight: bold;
    }

    .payment-content-package-text {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #FF9500;
        margin-top: 20px;
    }

    .form-payment {
        color: #0077FF;
        padding-right: 10px;
    }

    .cancle-code {
        color: #0077FF !important;
        cursor: pointer;
    }

    .premium-text-payment {
        font-family: HelveticaNeue;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.36px;
        text-align: left;
        color: #000000;
    }

    .premium-text-payment.bold {
        font-weight: bold;
    }

    .premium-text-payment.green {
        color: #4cd964;
    }

    .img-phone-appear {
        display: none;
    }

    .qr-payment-section {
        text-align: center;
        margin: 15px;
        border: black solid 1px;
        margin: 20px 80px;
    }

    .payment-submit-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .invalid-email {
        color: red;
        font-weight: bold;
    }

    @media (max-width: 460px) {
        .promptpay-title {
            font-size: 12px;
        }

        .premium-text-payment {
            font-size: 16px;
        }

        .premuim-bank-select {
            display: inline-flex;
            position: static;
            width: 48%;
            text-align: center;
            height: auto;
            min-height: 12vh;
        }

        .img-phone {
            width: 25%;
        }

        .img-phone-disappear {
            display: none;
        }

        .premium-method-text {
            text-align: center;
        }

        .img-logo-phone {
            max-width: 80%;
            height: auto;
        }

        .img-phone-appear {
            display: block;
        }
    }