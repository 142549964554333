.show-bg {
    background-color: #E9E9F2;
    /* padding: auto auto auto auto; */
    padding: 20px 100px 20px 100px;
}

.list-bg {
    border-radius: 20px;
    background-color: #FFFFFF;
}

.view-all-history {
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 10px;
    right: 60px;
    top: 1420px;
    background: #EFEFF4;
    border-radius: 12px;
    margin-right: 15px;
    display: inline-block;
    text-align: center;
}

.form-history {
    background-color: #EFEFF4;
    border: none;
    text-align: left;
    color: #0077FF;
}

.topic-name-list {
    font-size: clamp(10px, 1vw, 12px);
}

.video-playlist {
    background: #EFEFF4;
    border-radius: 5px;
    text-align: center;
    font-size: 11px;
    padding: 5px 0px;
    color: #8E8E93;
    margin-top: 7px;
    margin-bottom: 10%;
    cursor: pointer;
}

.img-playlist {
    vertical-align: baseline;
    margin-right: 3px;
}

.content-ls-topic-list {
    background: white;
    border-radius: 1vw;
    padding: 15px;
}



#topic-list-main {
    height: 89vh;
    overflow-y: auto;
}

#topic-list-container {
    background: #e9e9f2;
    padding: 30px;
}

#topic-list-loading {
    margin: auto;
    padding: 9% 0;
    text-align: center;
}

#topic-list-loading img {
    max-height: 250px;
}

#topic-list-empty {
    margin: auto;
    padding-top: 14% 0;
    text-align: center;
}

#topic-list-item-container {
    padding: 20px 15px 0;
}



@media (max-width: 1024px) {
    .content-ls-topic-list {
        margin-bottom: 30vh;
        /* 25vh */
    }
}