.course-price-card {
    margin-top: 2%;
    background-color: white;
    border-radius: 1vw;
    padding: 20px 0px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%);
    width: 100%;
    border: #ffcc00 3px solid;
    margin-bottom: 40px;
}

.course-price-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    margin-bottom: 15px;
}

.course-price-title {
    font-size: clamp(30px, 3vw, 40px);
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.course-list-scroll-left-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
    transform: rotate(180deg);
}

.course-list-scroll-right-button {
    width: clamp(22px, 3vw, 44px);
    cursor: pointer;
}

.course-price-description {
    font-size: clamp(16px, 1.4vw, 28px);
    padding: 0px 10px;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
}

.course-list-scroll-container {
    display: flex;
    justify-content: center;
    gap: 30%;
}

.course-list-container {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    white-space: nowrap;
    scroll-behavior: smooth;
    flex-wrap: nowrap !important;
    margin: auto !important;
}

.course-list-container::-webkit-scrollbar {
    display: none;
}

.main-course {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-item {
    border-radius: 1vw;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    border: #F4F5F9 2px solid;
    width: 18vw;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: auto;
}

.course-item:hover {
    border: #ffcc00 2px solid
}

.course-item .badge-img {
    width: 5.5vW;
    top: 0px;
    right: 0px;
    position: absolute;
}

.course-item .product-description {
    font-size: large;
    margin-top: 15px;
}

.course-item .bargain-description {
    min-height: 24px;
    color: #ffb700;
}

.course-item .bargain-container {
    margin-top: 8px;
}

.course-item .bargain-container .bargain-old-price {
    text-decoration-line: line-through;
    color: #5B5C62;
    font-size: larger;
    font-weight: bold;
}

.course-item .bargain-container-blank {
    min-height: 38px;
}

.course-item .price-main {
    font-size: 40px;
    margin-top: -18px;
}

.course-item .payment-description {
    margin: 10px;
    color: grey;
    font-size: smaller;
}

.course-item .subscribe-button {
    border: #e9e9f2 solid 2px;
    border-radius: 10px;
    margin: 10px 45px;
    padding: 7px;
}

.course-item-images {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    max-width: 80%;
}

.course-item-images .course-item-image-container {
    color: gray;
    font-size: smaller;
    margin-right: 5px;
    margin-left: 5px;
}

.course-item-images .course-item-image-container .course-item-image {
    max-width: 100%;
    height: auto;
}

.course-price-index-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.course-price-index-point {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
    background-color: #d1d1d6;
    border-radius: 100%;
}

.course-price-index-point-active {
    width: 10px;
    height: 10px;
    margin: 0px 5px;
    background-color: black;
    border-radius: 100%;
}

@media (max-width: 1024px) {

    .main-course {
        margin: auto;
    }

    .course-item {
        width: 80%;
    }

    .course-item .badge-img {
        width: 25.5vW;
    }
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
    .course-item {
        width: 36vw;
    }
}

@media (max-width: 575px) {

    .course-list-scroll-left-button,
    .course-list-scroll-right-button {
        display: none;
    }
}