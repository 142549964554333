.bg-grey {
    background-color: #E9E9F2;
}

.ranking {
    background-image: url("../../images/background/celebrate_bg.svg");
    background-color: #171C21;
    border-radius: 10px;
    text-align: center;
    color: white;
    padding: 15px;
}

.ranking-detail {
    border: 4px solid #006CFF;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: black;
}

.answer-btn {
    background: #0B77FF;
    margin: auto;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.graph-point {
    background: #FFFFFF;
    border: 2px solid #EFEFF4;
    border-radius: 20px;
    min-height: 300px;
    padding: 10px;
}

.exam-point {
    background: #FFFFFF;
    border: 2px solid #EFEFF4;
    border-radius: 20px;
    padding: 10px;
}

.score-table {
    background-color: #EFEFF4;
    border-radius: 10px;
    text-align: center;
    padding: 0px;
    /* overflow: auto; */
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.score-arrow {
    background-color: #BBDBFF;
    width: 20%;
    float: right;
    height: 100%;
    position: absolute;
    right: 0;
    padding-top: 30%;
}

.score-score {
    background: #F7F7F8;
    width: 40%;
    float: left;
    clear: left;
    margin-right: 1px;
    text-align: center;
}

.score-rank {
    background: #F7F7F8;
    width: 40%;
    float: left;
    margin-left: 1px;
}

.score-avg {
    width: 80%;
    float: left;
}

.next-arrow {
    max-width: 10px;
}

.score-point {
    color: #0B7AFF;
    font-size: 20px;
    font-weight: 900;
}

.score-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.score {
    font-weight: 700;
}