#filter {
    margin-top: 16px;
    margin-bottom: 16px;
}

.filter-by-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-by-text {
    font-size: 15px;
    font-weight: bold;
}

.input-filter select {
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    height: 44px;
    padding-left: 10px;
    border-radius: 12px;
    border: solid 1px #d1d1d6;
    background-color: #efeff4;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: black;
}

.input-filter .drop {
    left: unset !important;
    right: 20px !important;
    width: 13px !important;
    height: 13px !important;
}

.input-filter img {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 0;
    bottom: 0;
    left: 25px;
    margin: auto;
}

@media only screen and (max-width: 663px) {
    .filter-by-container {
        justify-content: center;
    }
    .input-filter select {
        padding-left: 5px;
        border: solid 1px white;
        background-color: white;
    }
    .input-filter .drop {
        left: unset !important;
        right: 17px !important;
        width: 10px !important;
        height: 10px !important;
    }
}

@media only screen and (max-width: 768px) {
    #content {
        width: 100% !important;
    }
    .box-mobile {
        min-height: 15vh;
        color: rgba(0, 0, 0, 0);
    }
}

#content {
    width: 96%;
}

#sidebar-container-main {
    background-color: #FFFFFF;
}
