aside {
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: #efeff4;
}

.video-layout {
    width: 100%;
    margin: auto;
}

.doc-layout {
    border: 2px solid #d1d1d6;
    background-color: #efeff4;
    width: 90%;
}

.no-padding {
    padding: 0px !important;
}

.icon-resize {
    cursor: ew-resize;
    position: absolute;
    left: -3.5%;
    top: 1%;
}

.chapter-item {
    width: 100%;
    min-height: 60px;
    font-weight: bold;
    height: auto;
    text-overflow: ellipsis;
    overflow: auto;
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: clamp(15px, 1.1vw, 20px);
    font-weight: bold;
}

.chapter-tiem-status-success-image {
    width: clamp(10px, 0.8vw, 15px);
    ;
    height: clamp(10px, 0.8vw, 15px);
    ;
    margin-right: 7px;
}

.chapter-tiem-status-default-image {
    width: clamp(7px, 0.6vw, 10px);
    height: clamp(7px, 0.6vw, 10px);
    margin-left: 2px;
    margin-right: 9px;
}

.state-item {
    height: 60px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #e5e5ea;
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    padding: 8px;
    align-items: center;
}

.state-item * {
    padding: 2px;
}

.active {
    border: 1px solid #ffcd01;
}

.chapter-item:hover {
    background-color: #efeff4;
}

.state-item:hover {
    background-color: #efeff4;
}


/* split pane css */

.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

.vod-chapter-description {
    margin-left: 20px;
    font-weight: bolder;
    font-size: clamp(20px, 1.7vw, 30px);
}

.vod-chapter-description-detail {
    margin-left: 20px;
    margin-top: 12px;
    font-size: clamp(12px, 0.9vw, 15px);
}

.vod-classroom-name {
    font-size: clamp(20px, 1.7vw, 30px);
    font-weight: bold;
}

.vod-lesson-name {
    font-size: clamp(15px, 1.1vw, 20px);
    font-weight: bold;
}

.vod-sibebar-bg-container {
    background-color: #e9e9f2;
}

.vod-sidebar-container {
    background-color: #f7f7fc;
    border-radius: 20px;
    margin: 20px 0px;
    padding: 20px;
    border: 1px solid #fcfcfd;
}

.vod-accordion-item-container {
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #e9e9f2;
    margin-bottom: 15px;
}

.vod-chapter-menu-title {
    font-size: clamp(12px, 0.9vw, 15px);
    margin-left: clamp(6px, 0.6vw, 12px);
}

.vod-chapter-menu-title-active {
    font-size: clamp(12px, 0.9vw, 15px);
    font-weight: bold;
    margin-left: clamp(6px, 0.6vw, 12px);
}

.vod-lock-container {
    width: 30px;
    height: 30px;
    background-color: #e5e5ea;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.vod-lock-image {
    width: 15px;
    height: 18px;
}

.vod-can-play-image {
    width: clamp(24px, 1.8vw, 30px);
    height: auto;
}

.vod-chapter-description-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.three-dot-menu-icons li {
    background: none repeat scroll 0 0 #81869d;
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    margin-right: 15px;
    margin-top: 3px;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
}

.vod-chapter-dropdown-button {
    background-color: white;
    border: none;
    cursor: pointer;
}

.vod-chapter-dropdown {
    display: inline-block;
    position: static;
    right: 0.4em;
}

.vod-chapter-dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 15px;
}

.vod-chapter-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.show-dropdown-content {
    display: block !important;
}

.hide-dropdown-content {
    display: none !important;
}

.vod-modal-payment {
    margin: auto;
    width: 45vw;
    text-align: center;
}