#mini-test-content {
  background-color: #f7f7fc;
  padding: 20px;
}

.mini-test-title {
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
}

.mini-test-question-index-container {
  width: 20px;
  height: 22px;
  background-color: #0b7aff;
  border-width:0.5px 0px 0.5px 0px;
  border-style: solid;
  border-color: #efeff4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.mini-test-question-index-text {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.mini-test-question-title {
  font-size: 18px;
  font-weight: bold;
}

.mini-test-question-image-container {
  width: 100%;
}

.mini-test-question-image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
  object-fit: cover;
  border: 1px solid #efeff4;
  border-radius: 6px;
}

.mini-test-submit-container {
  width: 70%;
  height: 150px;
  margin-left: 15%;
}

.submit-answer-button {
  width: 100%;
  height: 50px;
}

.mini-test-score-container {
  width: 100%;
  height: 260px;
  background-size: 100% 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-test-score-image-crown {
  margin-right: 20px;
  width: 100px;
  height: 100px;
}

.mini-test-score-text-score {
  margin: 0;
  font-weight: bold;
  font-size: 40px;
  color: white;
  text-align: center;
}

.mini-test-score-text-score-unit {
  margin: 0;
  font-weight: bold;
  font-size: 12px;
  color: white;
  text-align: center;
}

.mini-test-answer-container-text {
  display: flex;
  align-items: center;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
}

.mini-test-answer-container-image {
  margin: 10px 0px 10px 0px;
  cursor: pointer;
}

.mini-test-answer-choice-image {
  margin-right: 10px;
  width: 24px;
}

.mini-test-answer-image-container {
  width: 100%;
  margin: 10px 0px 0px 0px;
}

.mini-test-try-again-and-next-lesson-container {
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.mini-test-try-again-button {
  height: 100%;
  width: 47%;
  background-color: white;
  border-width: 2px 2px 2px 2px;
  border-color: #0079ff;
  border-radius: 8px;
  font-size: 15px;
  color: #0079ff;
  font-weight: bold;
}

.mini-test-next-lesson-button {
  height: 100%;
  width: 47%;
  background-color: #0079ff;
  border-radius: 8px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.mini-test-segmentation-line {
  width: 100%;
  height: 2px;
  background-color: #efeff4;
}

.mini-test-show-answer-title {
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
}

.mini-test-question-card {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin: 20px 10px;
}

.mini-test-question-item {
  display: -webkit-box;
}

.mini-test-question-detail-container {
  padding-right: 25px;
}

.mini-test-answer-choice-index {
  width: 50px;
  height: 34px;
  border-radius: 6px;
  border: solid 1px #efeff4;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: black;
}

.mini-test-answer-choice-index-active {
  width: 50px;
  height: 34px;
  border-radius: 6px;
  border: solid 1px #0b7aff;
  background-color: #0b7aff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: white;
}

.mini-test-answer-choice-index-correct {
  width: 50px;
  height: 34px;
  border-radius: 6px;
  border: solid 1px #48da64;
  background-color: #48da64;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: white;
}

.mini-test-answer-choice-index-incorrect {
  width: 50px;
  height: 34px;
  border-radius: 6px;
  border: solid 1px #ff3c2f;
  background-color: #ff3c2f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: white;
}

.answer-reason-container {
  border-radius: 6px;
  border: solid 1px #efeff4;
  background-color: #f8f9fd;
  margin-top: 20px;
  padding: 16px;
}

.answer-reason-title {
  font-size: 20px;
  font-weight: bold;
}

.answer-reason-image-container {
  width: 100%;
}

.answer-reason-image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #efeff4;
  margin-top: 10px;
  object-fit: cover;
}

.answer-reason-text {
  font-size: 16px;
  margin-top: 10px;
  white-space: pre-line;
}