.payment-content-body {
    width: 80%;
    max-width: 500px;
    margin-bottom: 50px;
    /* 25vh */
}

@media (max-width: 1024px) {
    .payment-content-body {
        margin-bottom: 30vh;
        /* 25vh */
    }
}